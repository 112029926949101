import React from 'react'
import moment from 'moment'

const getAssignmentWithPendingClientLenderOffer = (orderData) => {
  const assignmentsWithOffers = orderData.assignment_list.filter(
    assignment => assignment.offers && assignment.offers.length > 0
  )
  return assignmentsWithOffers.find(
    assignment => assignment.offers.some(offer => offer.is_pending_client_lender)
  )
}

const getFirstPendingClientLenderOffer = (orderData) => {
  const assignmentsWithOffers = orderData.assignment_list.filter(
    assignment => assignment.offers && assignment.offers.length > 0
  )
  return assignmentsWithOffers.find(
    assignment => assignment.offers.some(
      offer => offer.is_pending_client_lender
    )
  )?.offers.find(
    offer => offer.is_pending_client_lender
  )
}

const commonFieldOptions = [
  {
    id: 'borrower_name',
    display: 'Borrower Name',
    example: 'Jane Smith',
    getValue: (orderData) => {
      const consumers = orderData.schedule?.consumers || []
      return consumers.find(consumer => consumer.role === 'borrower')?.name
    },
    formatter: (name) => name || 'N/A',
  },
  {
    id: 'vendor_name',
    display: 'Vendor Name',
    example: 'John Doe',
    getValue: (orderData) => {
      return orderData?.accepted?.panelist_name
    },
    formatter: (name) => name || 'N/A',
  },
  {
    id: 'total_appraiser_fee',
    display: 'Total Appraiser Fee',
    value: 'total_appraiser_fee',
    example: '$100',
    formatter: (fee) => fee ? `$${fee}` : 'N/A',
  },
]

const ampTemplatedFieldOptions = [
  ...commonFieldOptions,
  {
    id: 'appraiser_due_date',
    display: 'Appraiser Due Date',
    value: 'due_date',
    example: '10/01/2024',
    formatter: (date) => moment(date).format('MM/DD/YYYY'),
  },
  {
    id: 'lender_due_date',
    display: 'Lender Due Date',
    value: 'lender_due_date',
    example: '10/01/2024',
    formatter: (date) => moment(date).format('MM/DD/YYYY'),
  },
  {
    id: 'offer_fee',
    display: 'Pending Offer Fee',
    example: '$100',
    getValue: (orderData) => {
      const offer = getFirstPendingClientLenderOffer(orderData)
      return offer?.fee
    },
    formatter: (fee) => fee ? `$${fee}` : 'N/A',
  },
  {
    id: 'offer_due_date',
    display: 'Pending Offer Due Date',
    example: '10/01/2024',
    getValue: (orderData) => {
      const offer = getFirstPendingClientLenderOffer(orderData)
      return offer?.due_date
    },
    formatter: (date) => date ? moment(date).format('MM/DD/YYYY') : 'N/A',
  },
  {
    id: 'offer_reason',
    display: 'Pending Offer Reason',
    example: 'Reason for offer',
    getValue: (orderData) => {
      const offer = getFirstPendingClientLenderOffer(orderData)
      return offer?.reason
    },
    formatter: (reason) => reason || 'N/A',
  },
  {
    id: 'offer_company_name',
    display: 'Pending Offer Company Name',
    example: 'Company Name',
    getValue: (orderData) => {
      const assignment = getAssignmentWithPendingClientLenderOffer(orderData)
      return assignment?.company?.name
    },
    formatter: (companyName) => companyName || 'N/A',
  },
]

const nonAmpTemplatedFieldOptions = [
  ...commonFieldOptions,
  {
    id: 'due_date',
    display: 'Due Date',
    value: 'lender_due_date',
    example: '10/01/2024',
    formatter: (date) => moment(date).format('MM/DD/YYYY'),
  },
]

export const getTemplatedFieldOptions = (isAmcLender) => {
  return isAmcLender ? ampTemplatedFieldOptions : nonAmpTemplatedFieldOptions
}

export const HELPER_TEXT = (
  <span className='templated-helper-text'>
    Type the &apos;@&apos; symbol to see what order data can be included in the templated message.
  </span>
)
