/* eslint-disable react/no-unescaped-entities */
import React, { Component, Fragment } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'
import { Input } from 'antd'
import TemplatedTextArea from '@app/pages/adminSettings/TemplatedTextArea/TemplatedTextArea'
import { HELPER_TEXT } from './helpers'

export default class CommentTemplateModal extends Component {
  state = {
    templateName: '',
    templateText: '',
    currentError: '',
  }

  onSubmit = () => {
    const {
      templateName,
      templateText,
    } = this.state

    const submitArgs = {
      name: templateName,
      text: templateText,
    }

    this.props.templates.post(submitArgs)
      .then(this.getTemplates)
      .then(this.props.refreshOrder)
  }

  getTemplates = () => {
    this.props.templates.fetch()
  }

  render() {
    const {
      templateName,
      templateText,
      currentError,
    } = this.state

    const {
      templatedFieldOptions,
    } = this.props

    return (<Fragment>
      <ModalHeader>Create New Message Template</ModalHeader>
      <ModalBody>
        <Fragment>
          <label className='template-label'>Message Name</label>
          <Input
            className='my-2'
            onChange={(e) => this.setState({ templateName: e.target.value })}
            value={templateName}
          />

          <label className='template-label'>Message Text</label>
          {templatedFieldOptions && templatedFieldOptions.length > 0
            ? <TemplatedTextArea
              validFields={templatedFieldOptions}
              value={templateText}
              onChange={value => this.setState({ templateText: value })}
              name='templateText'
              error={currentError}
              setError={error => this.setState({ currentError: error })}
              className='templated-input'
              helperText={HELPER_TEXT}
            />
            : <Input.TextArea
              className='my-2'
              autoSize={{ minRows: 4, maxRows: 6 }}
              rows={4}
              onChange={(e) => this.setState({ templateText: e.target.value })}
              value={templateText}
            />
          }
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Button onClick={this.props.onHide}>Close</Button>
        <Button
          color='primary'
          onClick={this.onSubmit}
        >
            Confirm
        </Button>
      </ModalFooter>
    </Fragment>)
  }
}
