import React, { useEffect, useState, useCallback } from 'react'

import { Alert } from 'reactstrap'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ResultTable } from './ResultTable'
import { selectRepurchaseWarrantySubmissionByVersion, selectRepurchaseWarrantyReviewResultsByVersion } from '@store/reducers/submissionReviews/submissionReviewsSelectors'
import { useSelector, useDispatch } from 'react-redux'
import { fetchRepurchaseWarrantyReviewResults } from '@store/reducers/submissionReviews/submissionReviewsSlice'
import ReviewNotes from './ReviewNotes'
import { Tag } from 'antd'
import { repurchaseWarrantyStatusToTag, WarrantyStatusIcon } from '../../Warranty/OrderWarrantyStatus'
import { REPURCHASE_WARRANTY_STATUSES } from '@app/common/constants/warranty'


const columns = [
  {
    title: <span>Message</span>,
    dataIndex: 'lender_message',
    minWidth: 150,
  },
]

/**
 * @param {string|undefined} submissionReviewId
 */
const useFetchReviewResults = (submissionReviewId) => {
  const dispatch = useDispatch()
  const [resultsLoading, setResultsLoading] = useState(false)

  const getReviewResults = useCallback(() => {
    if (submissionReviewId) {
      setResultsLoading(true)
      dispatch(fetchRepurchaseWarrantyReviewResults({ result_id: submissionReviewId }))
    }
  }, [dispatch, submissionReviewId])

  useEffect(() => {
    getReviewResults()
  }, [getReviewResults])

  return { resultsLoading, setResultsLoading }
}

const WarrantyStatusBanner = ({ repurchaseWarranty }) => {
  const { messages: rawMessages, status } = repurchaseWarranty || {}
  const messages = rawMessages?.filter(Boolean)
  const color = repurchaseWarrantyStatusToTag[status]
  const messageByStatus = {
    [REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]: 'This appraisal is qualified for Reggora Warranty',
    [REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED]: 'This appraisal does not qualify for Reggora Warranty',
  }
  const message = messageByStatus[status]

  if (!message) { return null }

  return (
    <div className='repurchase-warranty__banner'>
      <Tag
        className='h5 status-bar d-flex flex-column'
        color={color}
        style={{ padding: '0.5rem 1rem', margin: '0', width: '100%' }}
      >
        <div
          className='status_message'
          data-test-id="warranty-banner"
          style={{ marginBottom: messages?.length > 0 ? '0.5rem' : '0' }}
        >
          <WarrantyStatusIcon status={status} />
          <span>{message}</span>
        </div>
        {messages?.map((msg, index) => (
          <div data-test-id={`warranty-banner-message-${index + 1}`} key={index}>{msg}</div>
        ))}
      </Tag>
    </div>
  )
}

export function RepurchaseWarranty(props) {
  const {
    isEditable,
    orderData,
    submission,
    submissionVersion,
  } = props

  const [resultData, setResultData] = useState([])
  const submissionReview = useSelector((state) => selectRepurchaseWarrantySubmissionByVersion(state, submissionVersion))
  const reviewResults = useSelector((state) => selectRepurchaseWarrantyReviewResultsByVersion(state, submissionVersion))

  const { resultsLoading, setResultsLoading } = useFetchReviewResults(submissionReview?._id)
  const hasResults = resultsLoading || resultData?.length > 0


  useEffect(() => {
    const parsedRules = reviewResults ? Object.values(reviewResults) : []
    setResultData(parsedRules)
  }, [reviewResults, submission.version])

  useEffect(() => {
    if (reviewResults && resultsLoading) {
      setResultsLoading(false)
    }
  }, [reviewResults, resultsLoading])

  if (!submissionReview) {
    return (
      <Alert color='light' className='mb-0'>
        <ExclamationCircleOutlined className='mr-1' />
            This submission has not yet undergone Reggora Warranty
      </Alert>
    )
  }

  return (
    <div className='repurchase-warranty__container'>
      <WarrantyStatusBanner repurchaseWarranty={submission?.repurchase_warranty} />
      <div className="results-table">
        {
          hasResults && <ResultTable columns={columns} data={resultData} isLoading={resultsLoading} />
        }
        <ReviewNotes
          orderId={orderData.id}
          submissionVersion={submissionVersion}
          isEditable={isEditable}>
        </ReviewNotes>
      </div>
    </div>
  )
}
