import React from 'react'
import { Toolbar, ToolbarTitle, ToolbarActions } from '../../common/layout/components/Toolbar'
import OrderHeaderButtons from './OrderHeaderButtons'
import OrderWarrantyStatus from './Warranty/OrderWarrantyStatus'

const OrderToolbar = (props) => {
  const {
    loanNumber,
    county,
    address,
    loanData,
    orderData,
    using_amc_lender,
    refreshOrder,
    history,
    onReassignOrder,
    is_amc_lender,
    loanIdForEncompass,
  } = props

  return (
    <Toolbar>
      <ToolbarTitle>
        #{loanNumber} <span className="font-weight-bold ml-1">{address}</span>
        {county && (
          <span className="ml-2">
            ({county} County)
          </span>
        )}
      </ToolbarTitle>
      <OrderWarrantyStatus orderData={orderData} />
      <ToolbarActions>
        <OrderHeaderButtons
          loanData={loanData}
          orderData={orderData}
          using_amc_lender={using_amc_lender}
          refreshOrder={refreshOrder}
          history={history}
          onReassignOrder={onReassignOrder}
          is_amc_lender={is_amc_lender}
          loanIdForEncompass={loanIdForEncompass}
        />
      </ToolbarActions>
    </Toolbar>
  )
}

export default OrderToolbar
