import React, { Component, Fragment } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Input } from 'antd'
import ModalConfirmation from '../../../common/modals/ModalConfirmation'
import ModalTrigger from '../../../common/modals/ModalTrigger'
import TemplatedTextArea from '../../../pages/adminSettings/TemplatedTextArea'
import { HELPER_TEXT } from './helpers'

export default class CommentTemplateAccessModal extends Component {
    allTemplates = this.props.templates.data.templates
    state = {
      inputVal: null,
      results: this.allTemplates,
      selected: null,
      templateName: '',
      templateText: '',
      currentError: '',
    }

    getTemplates = () => {
      this.props.templates.fetch()
    }

    onChange = (selected) => {
      this.setSelectedTemplate(selected[0])
    }

    setSelectedTemplate = (template) => {
      if (template && template !== this.state.selected) {
        this.setState({
          selected: template,
          templateName: template.name,
          templateText: template.text,
        })
      } else {
        this.setState({
          selected: null,
          templateName: null,
          templateText: null,
        })
      }
    }

    onSubmit = () => {
      const {
        selected,
        templateName,
        templateText,
      } = this.state

      if (!selected) { return }

      const submitArgs = {
        name: templateName,
        text: templateText,
      }
      if (this.props.editing) {
        const id = this.state.selected.id
        submitArgs.id = id
        this.props.templates.put(submitArgs)
          .then(this.getTemplates)
          .then(this.props.refreshOrder)
      } else {
        this.props.setTextToInsert(templateText)
        this.props.onHide()
        this.props.setDropdownVisibility(false)
      }
    }

    onDelete = () => {
      const id = this.state.selected.id
      this.props.templates.remove({ id })
        .then(this.getTemplates)
        .then(this.props.refreshOrder)
    }

    render() {
      const { templatedFieldOptions } = this.props
      const { currentError, templateText } = this.state

      return (
        <Fragment>
          <ModalHeader>{this.props.editing ? 'Edit ' : 'Select '}Message Template</ModalHeader>
          <ModalBody>
            <div>Search For Template</div>
            <Typeahead
              id='CommentTemplateAcessModal'
              className='my-2'
              tabIndex={0}
              options={this.allTemplates}
              labelKey={'name'}
              selectHintOnEnter={true}
              clearButton={true}
              onChange={this.onChange}
            />

            {
              this.state.selected && this.props.editing &&
                        <div className='mt-2'>
                            Edit Template Name:
                        </div>
            }

            {
              this.state.selected && this.props.editing &&
                        <Input
                          className='mt-2'
                          onChange={(e) => this.setState({ templateName: e.target.value })}
                          value={this.state.templateName}
                        />
            }

            {
              this.state.selected &&
                        <div className='mt-2'>
                          {this.props.editing ? 'Edit ' : null} Template Text:
                        </div>
            }


            {
              this.state.selected && !this.props.editing &&
                        <div className='templateText mt-2'>
                          {this.state.selected.text}
                        </div>
            }

            {
              this.state.selected && this.props.editing && (
                <>
                  {templatedFieldOptions && templatedFieldOptions.length > 0
                    ? <TemplatedTextArea
                      validFields={templatedFieldOptions}
                      value={templateText}
                      onChange={value => this.setState({ templateText: value })}
                      name='templateText'
                      error={currentError}
                      setError={error => this.setState({ currentError: error })}
                      className='templated-input'
                      helperText={HELPER_TEXT}
                    />
                    : <Input.TextArea
                      className='my-2'
                      autoSize={{ minRows: 4, maxRows: 6 }}
                      rows={4}
                      onChange={(e) => this.setState({ templateText: e.target.value })}
                      value={templateText}
                    />
                  }
                </>
              )
            }

          </ModalBody>
          <ModalFooter>
            {this.props.editing && this.state.selected &&
                        <ModalTrigger
                          component={ ModalConfirmation }
                          onConfirm={this.onDelete}
                          confirmationText='Are you sure you want to delete this Message Template?'
                          dismissBtn='No'
                          confirmBtn='Yes'
                          modalClassName='modal-primary'
                        >
                          <Button className='mr-auto' color='danger' onClick={this.props.delete}>Delete</Button>
                        </ModalTrigger>
            }
            <Button onClick={this.props.onHide}>Close</Button>
            <Button color='primary' onClick={this.onSubmit}>Confirm</Button>
          </ModalFooter>
        </Fragment>
      )
    }
}
