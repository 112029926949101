import React from 'react'

const ToolbarTitle = ({ children }) => (
  <div className="d-flex align-items-center">
    <h4 className="mb-0">
      {children}
    </h4>
  </div>
)

export default ToolbarTitle
