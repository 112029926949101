import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Button as ButtonAntd } from 'antd'
import { Button } from 'reactstrap'
import React, { useState } from 'react'
import { compose } from 'redux'
import { useSelector, connect } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { connectResource } from '../../../common/utils/resource'
import { selectIsAmcLender, selectLenderId } from '../../../store/reducers/user/userSelectors'
import CommentTemplateModal from './CommentTemplateModal'
import CommentTemplateAccessModal from './CommentTemplateAccessModal'
import ModalTrigger from '../../../common/modals/ModalTrigger'
import { checkPermission } from '../../../common/utils/helpers'
import { selectAllOrderData } from '../../../store/reducers/order/orderSelectors'
import { getTemplatedFieldOptions } from './helpers'

const CommentTemplateDropdown = ({
  name,
  onHide,
  refreshOrder,
  setTextToInsert,
  templates,
  antdStyle,
}) => {
  const [visible, setVisible] = useState(false)
  const { orderDataInMessageTemplates } = useFlags()
  const isAmcLender = useSelector(selectIsAmcLender)
  const orderData = useSelector(selectAllOrderData)
  const templatedFieldOptions = orderDataInMessageTemplates
    ? getTemplatedFieldOptions(isAmcLender)
    : []

  const setDropdownVisibility = (visible) => {
    setVisible(visible)
  }

  const setTemplatedTextToInsert = (text) => {
    let templatedText = text
    templatedFieldOptions.forEach((option) => {
      let value = option.getValue ? option.getValue(orderData) : orderData[option.value]
      if (option.formatter) {
        value = option.formatter(value)
      }
      templatedText = templatedText.replace(`{{${option.id}}}`, value)
    })
    setTextToInsert(templatedText)
  }

  const menu = [
    checkPermission('edit_message_templates') && (
      <Menu.Item key="0" className="order-action-dropdown-div">
        <ModalTrigger
          component={CommentTemplateModal}
          onHide={onHide}
          templates={templates}
          refreshOrder={refreshOrder}
          templatedFieldOptions={templatedFieldOptions}
        >
          <div className="border border-light" color="light">
            <Button className="text-left order-dropdown-button" block>
              Create New Message Template
            </Button>
          </div>
        </ModalTrigger>
      </Menu.Item>
    ),
    checkPermission('edit_message_templates') && (
      <Menu.Item key="1" className="order-action-dropdown-div">
        <ModalTrigger
          component={CommentTemplateAccessModal}
          onHide={onHide}
          templates={templates}
          refreshOrder={refreshOrder}
          editing={true}
          templatedFieldOptions={templatedFieldOptions}
        >
          <div className="border border-light" color="light">
            <Button className="text-left order-dropdown-button" block>
              Edit Message Templates
            </Button>
          </div>
        </ModalTrigger>
      </Menu.Item>
    ),
    <Menu.Item key="2" className="order-action-dropdown-div">
      <ModalTrigger
        component={CommentTemplateAccessModal}
        onHide={onHide}
        templates={templates}
        refreshOrder={refreshOrder}
        setDropdownVisibility={setDropdownVisibility}
        setTextToInsert={setTemplatedTextToInsert}
        templatedFieldOptions={templatedFieldOptions}
      >
        <div className="border border-light" color="light">
          <Button className="text-left order-dropdown-button" block>
            Insert Message Template
          </Button>
        </div>
      </ModalTrigger>
    </Menu.Item>,
  ]

  return (
    checkPermission('view_message_templates') && (
      <Dropdown
        overlay={<Menu name={name}>{menu}</Menu>}
        trigger={['click']}
        onVisibleChange={setDropdownVisibility}
        visible={visible}
      >
        {antdStyle ? (
          <ButtonAntd>
            {' '}
              Manage Message Templates <DownOutlined />
          </ButtonAntd>
        ) : (
          <Button className="ml-auto width-50">
            {' '}
              Manage Message Templates <DownOutlined />
          </Button>
        )}
      </Dropdown>
    )
  )
}

const mapStateToProps = state => ({
  lenderId: selectLenderId(state),
})

export default compose(
  connect(mapStateToProps),
  connectResource({
    namespace: 'templates',
    endpoint: 'lender/messageTemplate/:lenderId',
    prefetch: true,
    apiVersion: 2,
  })
)(CommentTemplateDropdown)
