export const mentionStyle = {
  backgroundColor: '#e9ecef',
}

export default {
  control: {
    backgroundColor: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      minHeight: '63px',
    },
    highlighter: {
      padding: '9px',
      border: '1px solid transparent',
    },
    input: {
      padding: '9px',
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: '180px',

    highlighter: {
      padding: '1px',
      border: '2px inset transparent',
    },
    input: {
      padding: '1px',
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: '14px',
      maxHeight: '275px',
      width: '225px',
      overflow: 'auto',
      position: 'absolute',
      bottom: '14px',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
}
