import { useCallback, useState } from 'react'

const renderPageToImage = async (page, width, height) => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')
  await page.render({ canvasContext: context, viewport: page.getViewport({ scale: 1.0 }) }).promise

  return context.getImageData(0, 0, canvas.width, canvas.height)
}

const calculatePixelsStats = (pixels) => {
  const xValues = pixels.map(p => p[0])
  const yValues = pixels.map(p => p[1])

  // Width and height of the bounding box.
  const width = Math.max(...xValues) - Math.min(...xValues) + 1
  const height = Math.max(...yValues) - Math.min(...yValues) + 1

  const pointCount = pixels.length

  // Density: points per area of bounding box.
  const density = pointCount / (width * height)

  return {
    density,
    height,
    pointCount,
    width,
  }
}


/**
 * Check if the diff is roughly square and within size thresholds.
 */
export const isCheckbox = (pixels) => {
  const MAX_ASPECT_RATIO_DIFF = 2 // Maximum aspect ratio difference to be considered a checkbox.
  const MIN_SIZE = 6 // Minimum size for width and height to be considered a checkbox.
  const MAX_SIZE = 30 // Maximum size for width and height to be considered a checkbox.
  const densityThreshold = [0.2, 0.5]
  const MIN_PIXELS = 10 // Minimum number of pixels to be considered a checkbox.

  const { density, height, pointCount, width } = calculatePixelsStats(pixels)

  const hasValidDensity = density >= densityThreshold[0] && density <= densityThreshold[1]
  const hasValidAspectRatio = Math.abs(width - height) <= MAX_ASPECT_RATIO_DIFF
  const hasValidPointCount = pointCount >= MIN_PIXELS
  const hasValidWidth = width >= MIN_SIZE && width <= MAX_SIZE
  const hasValidHeight = height >= MIN_SIZE && height <= MAX_SIZE

  const result = (
    hasValidAspectRatio &&
    hasValidDensity &&
    hasValidHeight &&
    hasValidPointCount &&
    hasValidWidth
  )

  return result
}

export const useCompareGraphics = () => {
  const [progress, setProgress] = useState(0)
  const [isProcessing, setIsProcessing] = useState(true) // For showing overlay during processing

  // Compare graphics (e.g., checkboxes)
  const compareGraphics = useCallback(async (pdfDoc1, pdfDoc2, alignedPages) => {
    const diffs = []
    const CHECKBOX_TOLERANCE = 5 // Define the tolerance for checking the checkbox (adjust as needed).
    const MIN_AREA = 40 // Define the minimum area threshold (adjust as needed).
    const MAX_AREA = 100 // If the area is too large, the difference is not processed.
    let diffIndex = 0

    for (let index = 0; index < alignedPages.length; index++) {
      const aligned = alignedPages[index]

      if (aligned.type === 'matched') {
        const pageNum1 = aligned.pageNum1
        const pageNum2 = aligned.pageNum2

        setProgress(Math.round(((index + 1) / alignedPages.length) * 100)) // Update progress

        if (pageNum1 && pageNum2) {
          const page1 = await pdfDoc1.getPage(pageNum1)
          const page2 = await pdfDoc2.getPage(pageNum2)

          const viewport1 = page1.getViewport({ scale: 1.0 })
          const viewport2 = page2.getViewport({ scale: 1.0 })

          const maxWidth = Math.max(viewport1.width, viewport2.width)
          const maxHeight = Math.max(viewport1.height, viewport2.height)

          const [page1Img, page2Img] = await Promise.all([
            renderPageToImage(page1, maxWidth, maxHeight),
            renderPageToImage(page2, maxWidth, maxHeight),
          ])

          if (page1Img && page2Img) {
            const pixelVisited = Array.from({ length: Math.ceil(maxHeight) }, () =>
              Array.from({ length: Math.ceil(maxWidth) }, () => false)
            )

            const isRemovedPixel = (x, y) => {
              const idx = (y * maxWidth + x) * 4
              return (
                (page1Img.data[idx] !== 0 || page1Img.data[idx + 1] !== 0 || page1Img.data[idx + 2] !== 0) &&
                (page2Img.data[idx] === 0 && page2Img.data[idx + 1] === 0 && page2Img.data[idx + 2] === 0)
              )
            }

            const isAddedPixel = (x, y) => {
              const idx = (y * maxWidth + x) * 4
              return (
                (page2Img.data[idx] !== 0 || page2Img.data[idx + 1] !== 0 || page2Img.data[idx + 2] !== 0) &&
                (page1Img.data[idx] === 0 && page1Img.data[idx + 1] === 0 && page1Img.data[idx + 2] === 0)
              )
            }

            const groupPixels = (startX, startY, type) => {
              const queue = [[startX, startY]]
              let minX = startX; let maxX = startX; let minY = startY; let maxY = startY
              const pixels = []

              while (queue.length > 0) {
                const [x, y] = queue.shift()

                if (x < 0 || x >= maxWidth || y < 0 || y >= maxHeight || pixelVisited[y][x]) { continue }

                const isDiff = type === 'removed' ? isRemovedPixel(x, y) : isAddedPixel(x, y)
                if (!isDiff) { continue }

                pixelVisited[y][x] = true
                pixels.push([x, y])

                minX = Math.min(minX, x)
                maxX = Math.max(maxX, x)
                minY = Math.min(minY, y)
                maxY = Math.max(maxY, y)

                for (let dx = -CHECKBOX_TOLERANCE; dx <= CHECKBOX_TOLERANCE; dx++) {
                  for (let dy = -CHECKBOX_TOLERANCE; dy <= CHECKBOX_TOLERANCE; dy++) {
                    const newX = x + dx
                    const newY = y + dy
                    if (newX >= 0 && newX < maxWidth && newY >= 0 && newY < maxHeight) {
                      queue.push([newX, newY])
                    }
                  }
                }
              }

              return { minX, maxX, minY, maxY, type, pixels }
            }

            // Checkbox checked
            for (let y = 0; y < maxHeight; y++) {
              for (let x = 0; x < maxWidth; x++) {
                if (!pixelVisited[y][x] && isAddedPixel(x, y)) {
                  const { minX, maxX, minY, maxY, pixels, type } = groupPixels(x, y, 'added')
                  const area = (maxX - minX + 1) * (maxY - minY + 1)

                  if (area >= MIN_AREA && area <= MAX_AREA && isCheckbox(pixels)) {
                    diffs.push({
                      id: `graphic-diff-${index}-${diffIndex++}`,
                      x: minX,
                      y: minY,
                      width: maxX - minX + 1,
                      height: maxY - minY + 1,
                      page: index + 1,
                      type,
                    })
                  }
                }
              }
            }

            // Checkbox unchecked.
            for (let y = 0; y < maxHeight; y++) {
              for (let x = 0; x < maxWidth; x++) {
                if (!pixelVisited[y][x] && isRemovedPixel(x, y)) {
                  const { minX, maxX, minY, maxY, pixels, type } = groupPixels(x, y, 'removed')
                  const area = (maxX - minX + 1) * (maxY - minY + 1)

                  if (area >= MIN_AREA && area <= MAX_AREA && isCheckbox(pixels)) {
                    diffs.push({
                      id: `graphic-diff-${index}-${diffIndex++}`,
                      x: minX,
                      y: minY,
                      width: maxX - minX + 1,
                      height: maxY - minY + 1,
                      page: index + 1,
                      type,
                    })
                  }
                }
              }
            }
          }
        }
      } else {
        // Handle added or removed pages
        setProgress(Math.round(((index + 1) / alignedPages.length) * 100)) // Update progress
      }
    }

    setIsProcessing(false) // Hide progress bar when done
    return diffs
  }, [])

  return { compareGraphics, progress, isProcessing }
}
