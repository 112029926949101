import React, { useRef, useEffect } from 'react'
import { Popover } from 'antd'
import { MentionsInput, Mention } from 'react-mentions'
import { InfoCircleOutlined } from '@ant-design/icons'
import TemplateHelperText from './TemplateHelperText'
import defaultStyle, { mentionStyle } from './style.js'

const interpolatedTextRegex = /{{[^}}]*}}/g
const DEFAULT_HELPER_TEXT = 'Type the &apos;@&apos; symbol to see the list of variables that can be added to the email body.'

const TemplatedTextArea = ({
  validFields,
  value,
  disabled,
  onChange,
  error,
  setError,
  name,
  placeholder = 'Type here...',
  helperText = DEFAULT_HELPER_TEXT,
  className = '',
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      // Manually add form-control class to match other inputs
      inputRef.current.classList.add('form-control')
    }
  }, [inputRef])

  const getInvalidFields = inputValue => {
    // Collect all interpolated fields
    const interpolatedTexts = inputValue.match(interpolatedTextRegex)
    if (!interpolatedTexts) {
      return []
    }
    // Return any fields that are not in the validFields array
    const invalidFields = []
    interpolatedTexts.forEach(text => {
      const field = text.slice(2, -2)
      const valid = !validFields.some(validField => validField.id === field)
      if (valid) {
        invalidFields.push(text)
      }
    })
    return invalidFields
  }

  const validate = inputValue => {
    const invalidFields = getInvalidFields(inputValue)
    if (invalidFields.length === 0) {
      setError('')
    } else {
      setError(
                `Invalid variable(s) included in brackets: ${invalidFields.join(' - ')}.
                Please remove or replace with a valid variable.`
      )
    }
  }

  const handleChange = inputValue => {
    validate(inputValue)
    onChange(inputValue)
  }

  return (
    <div className={`custom-text-area ${className}`}>
      <div className='mb-3 helper-text'>
        <span>
          {helperText}
        </span>
        <Popover
          placement="rightTop"
          title="Handlebar Templating Help"
          overlayClassName="handlebar-info"
          mouseLeaveDelay={0.05}
          width="50%"
          content={
            <TemplateHelperText validFields={validFields} />
          }
        >
          <InfoCircleOutlined className="handlebar-icon" />
        </Popover>
      </div>
      <MentionsInput
        value={value}
        onChange={(_, value) => handleChange(value)}
        inputRef={inputRef}
        style={defaultStyle}
        a11ySuggestionsListLabel={'Available Data Fields'}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
      >
        <Mention
          markup="{{__id__}}"
          displayTransform={id => `{{${id}}}`}
          data={validFields}
          style={mentionStyle}
        />
      </MentionsInput>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  )
}

export default TemplatedTextArea
