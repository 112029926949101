import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Tag, Badge, Popover } from 'antd'
import {
  CheckCircleFilled,
  WarningFilled,
  MinusCircleFilled,
} from '@ant-design/icons'
import { REPURCHASE_WARRANTY_STATUSES } from '@app/common/constants/warranty'
import { ORDER_STATUS } from '@app/common/constants/orders'

export const repurchaseWarrantyStatusToTag = {
  [REPURCHASE_WARRANTY_STATUSES.DISABLED]: 'default',
  [REPURCHASE_WARRANTY_STATUSES.PENDING]: 'processing',
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_SKIPPED]: 'default',
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED]: 'warning',
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]: 'success',
  default: 'default',
}

export const repurchaseWarrantyByStatus = {
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED]: '#fa8c16',
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]: '#87d068',
}

const repurchaseWarrantyStatusToVisibility = {
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED]: true,
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]: true,
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_SKIPPED]: true,
  default: false,
}

const orderStatusVisibility = {
  [ORDER_STATUS.AMP_REVIEW]: true,
  [ORDER_STATUS.UNDER_REVIEW]: true,
  [ORDER_STATUS.SUBMITTED]: true,
  [ORDER_STATUS.REVISION_REQUESTED]: true,
  default: false,
}

const NotificationContent = (props) => {
  const { messages } = props
  return (
    <div>
      {messages.map((message, index) =>
        <p key={index}>{message}</p>
      )}
    </div>
  )
}

export const WarrantyStatusIcon = (props) => {
  const { status } = props

  switch (status) {
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED:
      return <CheckCircleFilled />
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED:
      return <WarningFilled />
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_SKIPPED:
      return <MinusCircleFilled />
    default:
      return <></>
  }
}


const OrderWarrantyStatus = (props) => {
  const {
    orderData,
  } = props

  const { viewWarrantyReviews } = useFlags()
  const warrantyState = orderData?.repurchase_warranty

  const orderConfigVisibility = viewWarrantyReviews &&
      (orderData?.review_engine?.repurchase_warranty_enabled)
  const orderStateVisibility = orderStatusVisibility[orderData?.statusKey] ?? orderStatusVisibility.default
  const warrantyStateVisibility =
      repurchaseWarrantyStatusToVisibility[warrantyState?.status] ?? repurchaseWarrantyStatusToVisibility.default
  const warrantyStateColor =
      repurchaseWarrantyStatusToTag[warrantyState?.status] || repurchaseWarrantyStatusToTag.default

  const visibilityChecks = [orderConfigVisibility, orderStateVisibility, warrantyStateVisibility]
  const showRepurchaseWarranty = visibilityChecks.every(Boolean)

  const messages = warrantyState?.messages || []
  const popoverTrigger = messages.length > 0 ? ['click'] : []

  if (!showRepurchaseWarranty) {
    return <></>
  }

  const cursor = popoverTrigger.length ? 'pointer' : 'default'

  return (
    <>
      <div className="d-flex ml-4" data-test-id="warranty-badge">
        <Popover content={<NotificationContent messages={messages} />} placement="bottom" trigger={popoverTrigger}>
          <Badge count={messages.length} status={warrantyStateColor} style={{ cursor }}>
            <Tag className='h5' style={{ cursor, padding: '0.5rem 1rem', margin: '0' }} color={warrantyStateColor}><WarrantyStatusIcon status={warrantyState?.status} /> Reggora Warranty</Tag>
          </Badge>
        </Popover>
      </div>
    </>
  )
}

export default OrderWarrantyStatus
