/**
 * Order `statusKey` values
 */
export const ORDER_STATUS = {
  PENDING_APPROVAL: 'pending_approval',
  WAITING_FOR_PAYMENT: 'waiting_for_payment',
  FINDING_APPRAISERS: 'finding_appraisers',
  ACCEPTED: 'accepted',
  INSPECTION_SCHEDULED: 'inspection_scheduled',
  INSPECTION_COMPLETED: 'inspection_completed',
  REVISION_REQUESTED: 'revision_requested',
  REVISION_SUBMITTED: 'revision_submitted',
  UNDER_REVIEW: 'under_review',
  AMP_REVIEW: 'amp_review',
  SUBMITTED: 'submitted',
}

// standard = primary order, alternate = followup order
export const ELLIE_MAE_PRODUCT_REQUEST_TYPE = {
  NEW_REQUEST_STANDARD: 'New_Request_Standard',
  NEW_REQUEST_ALTERNATE: 'New_Request_Alternate',
  NEW_REQUEST_AVM: 'New_Request_AVM',
  UPDATE_SERVICE_STANDARD: 'Update_Service_Standard',
  UPDATE_SERVICE_ALTERNATE: 'Update_Service_Alternate',
  UPDATE_SERVICE_AVM: 'Update_Service_AVM',
}

export const REPORT_RELATED_STATUS_KEYS = ['submitted', 'revision_requested']

export const STATUS_KEY_FINDING_APPRAISERS = 'finding_appraisers'

export const ACCEPTANCE_METHODS = {
  BID: 'bid',
  FIRST_COME_FIRST_SERVE: 'first_come_first_serve',
}

export const REQUEST_METHODS = {
  BROADCAST: 'broadcast',
  INDIVIDUALLY: 'individually',
  CASCADE: 'cascade',
}
