import React, { useEffect, useState, useRef, useCallback, useMemo, Fragment } from 'react'
import axios from 'axios'
import FileSaver from 'file-saver'
import { checkPermission, utcToLocal } from '../../../../common/utils/helpers'
import RuleComment from './ResultTable/CommentModal/RuleComment'
import AddComment from './ResultTable/AddComment'
import ResultTableActions from './ResultTable/ResultTableActions'
import { useDebounce } from '../../../../common/hooks/useDebounce'
import {
  Modal,
  Button,
  Switch,
  Input,
  Tooltip,
} from 'antd'

import {
  Alert,
} from 'reactstrap'
import {
  ExclamationCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { ResultTable } from './ResultTable'
import { ReviewLog } from './ReviewLog'
import ResultView from './ResultView'
import LegacyRevisionModal from '../../LegacyRevisionModal'
import AntdModalTrigger from '../../../../common/modals/antd/ModalTrigger'
import { selectCurrentUser, selectIsAmcLender } from '@store/reducers/user/userSelectors'
import { selectSubmissionReviewByVersion, selectSubmissionReviewResultsByVersion, selectRulesets } from '@store/reducers/submissionReviews/submissionReviewsSelectors'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { fetchReviewResults, postRuleComment } from '@store/reducers/submissionReviews/submissionReviewsSlice'
import { selectSubmissionFilesByVersionAndType } from '@store/reducers/submissionFiles/submissionFilesSelectors'
import apiClient from '../../../../apiClient'
import ReviewNotes from './ReviewNotes'
import ReviewDatapoints from './ReviewDatapoints'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { RuleBadge } from './RuleBadge'
import { ResultStatusFilter, useAddSystemOverrideFlag, useResultStatusTags } from './ResultStatusFilter'

export function ReggoraReview(props) {
  const {
    isEditable,
    orderData,
    refreshOrder,
    submission,
    submissionVersion,
  } = props

  const dispatch = useDispatch()
  const [resultData, setResultData] = useState([])
  const [failedData, setFailedData] = useState([])
  const [createdDate, setCreatedDate] = useState('')
  const [showTableView, setShowTableView] = useState(true)
  const [appraisalFileUrl, setAppraisalFileUrl] = useState('')
  const [resultsLoading, setResultsLoading] = useState(false)
  const [resultRulesetName, setResultRulesetName] = useState({
    name: '',
    key: '',
  })

  const searchInputText = useRef('')

  const [searchUpdated, setSearchUpdated] = useState(null)
  const is_amc_lender = useSelector(selectIsAmcLender)
  const submissionReview = useSelector((state) => selectSubmissionReviewByVersion(state, submissionVersion))
  const reviewResultsRaw = useSelector((state) => selectSubmissionReviewResultsByVersion(state, submissionVersion))
  const reviewResults = useAddSystemOverrideFlag({ reviewResults: reviewResultsRaw, submissionVersion })
  const currentUser = useSelector(selectCurrentUser)
  const appraisalPdf = useSelector((state) => selectSubmissionFilesByVersionAndType(state, submissionVersion, 'appraisal_pdf'), shallowEqual)
  const rulesets = useSelector((state) => selectRulesets(state))
  const submissionFilesApi = useMemo(() => apiClient(SERVICE_URL), [])
  const flags = useFlags()

  const { availableTags, filterRulesByOutcome, selectedTags, setSelectedTags } =
    useResultStatusTags(reviewResults, submissionVersion)


  const getReviewResults = useCallback(
    () => {
      if (submissionReview?._id) {
        setResultsLoading(true)
        dispatch(fetchReviewResults({ result_id: submissionReview?._id }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, submissionReview?._id],
  )

  useEffect(() => {
    if (rulesets && submissionReview) {
      if (rulesets.length === 0 || submissionReview.review_result?.length === 0) {
        setResultRulesetName({
          name: '',
          key: '',
        })
        return
      }

      const ruleset = rulesets.find(ruleset => ruleset.key === submissionReview.review_result?.[0].rule_set_key)
      if (ruleset) {
        setResultRulesetName({
          name: ruleset.name,
          key: ruleset.key,
        })
        return
      }

      setResultRulesetName({
        name: '',
        key: '',
      })
    }
  }, [rulesets, submissionReview])

  useEffect(() => {
    getReviewResults()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadFile = (url, fileName) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      FileSaver.saveAs(new Blob([response.data]), fileName)
    })
  }

  const HandleActions = (fieldData) => {
    return (
      <ResultTableActions
        fieldData={fieldData}
      />
    )
  }

  const HandleComments = (fieldData) => {
    const { fieldName, id, notes, submissionReviewId, isEditable, currentUser } = fieldData
    const [comment, setComment] = useState('')
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const showModal = () => { setIsModalOpen(true) }
    const handleOk = () => {
      dispatch(postRuleComment({
        note: comment,
        ruleId: id,
        user_type: 'LENDER',
        user_id: currentUser.id,
        submissionReviewId: submissionReviewId,
      }))
      setComment('')
      setIsModalOpen(false)
    }
    const handleCancel = () => { setIsModalOpen(false) }
    const handleCommentInputChange = (e) => {
      setComment(e.target.value)
    }
    return (
      <Fragment>
        <div className="comment-label">
          {(isEditable) ? (
            <Button onClick={showModal}>
              {notes?.length > 0 ? `View(${notes.length})` : '+'}
            </Button>
          ) : (
            <Button onClick={showModal}>
              {notes?.length > 0 ? `View(${notes.length})` : null}
            </Button>
          )}
        </div>
        {/* When we update antD past 4.23 this is goign to break. Yay. visible will change to open */}
        <Modal
          style={{ maxWidth: '415px' }}
          className="comment-modal"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          footer={(isEditable && checkPermission('reggora_review_action')) ? [
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleOk}
            >
              Add
            </Button>,
          ] : <div></div>}
        >
          <RuleComment
            fieldName={fieldName}
            commentData={notes}
            currentUser={currentUser}
          />
          {isEditable &&
            <AddComment
              comment={comment}
              onChange={handleCommentInputChange}
              minRows={1}
              maxRows={5}
            />
          }
        </Modal>
      </Fragment>
    )
  }

  const DisplayReggoraFindings = (fieldData) => {
    const { desc, assertions, engine_outcome, data_points } = fieldData
    return (
      <>
        <div className="findings-desc">
          {desc}
        </div>
        <ReviewDatapoints {...{ assertions, engine_outcome, data_points }} />
      </>
    )
  }

  const switchView = (isChecked) => {
    setShowTableView(!isChecked)
  }

  const columns = [
    {
      title: <span className="centered"><strong>Status</strong></span>,
      dataIndex: 'fieldData',
      width: 150,
      minWidth: 100,
      render: ({ display_outcome, outcome }) =>
        RuleBadge({ display_outcome, outcome }),
    },
    {
      title: <span><strong>Field(s)</strong></span>,
      dataIndex: 'field',
      minWidth: 150,
      width: 300,
    },
    {
      title: <span>Reggora Findings</span>,
      dataIndex: 'fieldData',
      minWidth: 150,
      render: (fieldData) => DisplayReggoraFindings(fieldData),
    },
    {
      title: <span>Comments</span>,
      dataIndex: 'fieldData',
      width: 150,
      minWidth: 100,
      align: 'center',
      render: (fieldData) => HandleComments(fieldData),
    },
    {
      title: <span>Actions</span>,
      dataIndex: 'fieldData',
      width: 175,
      minWidth: 200,
      align: 'center',
      render: (fieldData) => HandleActions(fieldData),
    },
  ]

  if (flags?.reviewActionLog) {
    columns.push({
      title: <span>Log</span>,
      dataIndex: 'fieldData',
      width: 85,
      minWidth: 100,
      align: 'center',
      render: (fieldData) => ReviewLog({ fieldData }),
    })
  }

  const filteredData = useMemo(() => {
    if (!resultData || resultData.length === 0) {
      return []
    }

    let filteredRules = filterRulesByOutcome(resultData)

    if (!searchInputText.current || searchInputText.current === '') {
      // Handle for no search input
      return filteredRules
    }

    const lowerTarget = searchInputText.current.toLowerCase()

    filteredRules = filteredRules.filter((item) => {
      const lowerDescription = item.description.toLowerCase()
      const includesDescription = lowerDescription.includes(lowerTarget)
      if (includesDescription === true) {
        return true
      }

      const lowerDataPoints = item.fieldData?.data_points?.some(dp => `${dp.name} ${dp.value}`.toLowerCase().includes(lowerTarget))

      return lowerDataPoints
    })

    return filteredRules
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUpdated, selectedTags, resultData])

  const updateSearched = useDebounce(() => {
    setSearchUpdated(Date.now())
  }, 500)


  useEffect(() => {
    if (typeof submissionReview === 'undefined' ||
      submissionReview === null) {
      return
    }

    const createdDateFormatted = utcToLocal(submissionReview.created).format('lll')
    setCreatedDate(createdDateFormatted)
  }, [submissionReview])

  useEffect(() => {
    const parseRules = () => {
      const outcomeOrder = { ERROR: 0, HARD_STOP: 1, SOFT_STOP: 2, NO_STOP: 3, MANUAL: 4, PASS: 5, SKIP: 6 }
      const parsedRules = Object.values(reviewResults).map((rule) => {
        return {
          key: rule._id,
          status: {
            ruleName: rule.rule_name,
            engine_outcome: rule.engine_outcome,
          },
          field: rule.fields_message,
          description: rule.lender_message,
          vendor_description: rule.vendor_message,
          fieldData: {
            assertions: rule.assertions,
            currentUser: currentUser,
            data_points: rule?.data_points || [],
            data_points_by_submission: rule?.data_points_by_submission || {},
            desc: rule.lender_message,
            display_outcome: rule.display_outcome,
            engine_outcome: rule.engine_outcome,
            fieldName: rule.fields_message,
            hasSystemOverride: rule.hasSystemOverride,
            id: rule._id,
            isEditable: isEditable,
            notes: rule.note_logs,
            outcome_override_logs: rule.outcome_override_logs,
            outcome: rule.outcome,
            submissionReviewId: rule.submission_review_result_id,
            submissionVersion: submission.version,
          },
          raw_rule: rule, // for react debug purposes
        }
      }).sort(function(a, b) {
        return outcomeOrder[a.status?.engine_outcome] - outcomeOrder[b.status?.engine_outcome]
      })
      return parsedRules
    }

    if (reviewResults) {
      const parsedRules = parseRules()
      setResultData(parsedRules)
      setFailedData(parsedRules.filter(rule =>
        ['HARD_STOP', 'SOFT_STOP', 'NO_STOP', 'REJECTED', 'MANUAL'].includes(rule.fieldData?.outcome)
      ))
    } else {
      setResultData([])
      setFailedData([])
    }
  }, [isEditable, reviewResults, currentUser, submission.version])

  useEffect(() => {
    if (reviewResults) {
      if (resultsLoading) {
        setResultsLoading(false)
      }
    }
  }, [reviewResults, resultsLoading])

  useEffect(() => {
    if (appraisalPdf && appraisalPdf.length > 0 && submissionFilesApi) {
      if (flags?.reggoraReviewPdfAnnotation) {
        if (submissionReview._id) {
          const url = `${SERVICE_URL}submission-reviews/rule-results/v1/doc?submission_review_result_id=${submissionReview._id}`
          setAppraisalFileUrl(url)
        }
      } else {
        submissionFilesApi.get(`submission-files/v1/${appraisalPdf[0]._id}`, { presign: true })
          .then(data => {
            if (data.presigned_url) {
              setAppraisalFileUrl(data.presigned_url)
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    } else {
      setAppraisalFileUrl('')
    }
  }, [appraisalPdf, submissionFilesApi, submissionReview?._id, flags?.reggoraReviewPdfAnnotation])

  const searchChange = (e) => {
    searchInputText.current = e.target.value
    updateSearched()
  }

  const tagsChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag)
    setSelectedTags(nextSelectedTags)
  }

  return (
    <>
      { (!submissionReview)
        ? (
          <>
            <Alert color='light' className='mb-0'>
              <ExclamationCircleOutlined className='mr-1' />
              This submission has not yet undergone Reggora Review
            </Alert>
          </>
        ) : (
          <>
            <div className='ucdp-results-table'>
              <div className='ucdp-summary align-items-center'>
                <div className='d-flex mr-auto'>
                  <div>
                    <Tooltip title={resultRulesetName.key} mouseEnterDelay={2}>
                      <p><strong>{resultRulesetName.name}</strong></p>
                    </Tooltip>
                    <p>Date Reviewed: {createdDate}</p>
                  </div>
                </div>
                <div className='d-flex ml-3 mr-0 align-items-center'>
                  <span className={'mx-2'}>Result Status</span>
                  <ResultStatusFilter
                    onChange={tagsChange}
                    availableTags={availableTags}
                    selectedTags={selectedTags}
                  />
                </div>
                <div className='d-flex ml-3 mr-0 align-items-center'>
                  <span className={'mx-2'}>Search</span>
                  <Input allowClear onChange={searchChange}></Input>
                </div>
                <div className='d-flex ml-3 mr-0'>
                  <span className={showTableView ? 'mx-1 font-weight-bold' : 'mx-1'}>Table</span>
                  <Switch className='review-toggle-switch' onChange={(value) => switchView(value)}></Switch>
                  <span className={!showTableView ? 'mx-1 font-weight-bold' : 'mx-1'}>PDF</span>
                </div>
                <div className='d-flex ml-3 mr-0'>
                  {checkPermission('revisions_create_edit') && !flags?.plat1860EnableMultiStepRevision && (
                    <AntdModalTrigger
                      modal={{
                        component: LegacyRevisionModal,
                        maskClosable: false,
                        size: 'large',
                      }}
                      order_id={orderData.id}
                      refetchOrder={refreshOrder}
                      is_amc_lender={is_amc_lender}
                      job_type={orderData.job_type}
                      resultData={failedData}
                      displayRuleSelect={true}
                      useCategories={flags.revisionCategories}
                    >
                      <Button>Create Revision</Button>
                    </AntdModalTrigger>
                  )}
                </div>
                {
                  submission.review_result_pdf &&
                  submission.review_result_pdf.document_url &&
                  <div className='d-flex ml-3 mr-0'>
                    <Button
                      onClick={() => downloadFile(
                        submission.review_result_pdf.document_url,
                        submission.review_result_pdf.document_name)
                      }>
                      <DownloadOutlined className='mr-1' />
                      Download PDF
                    </Button>
                  </div>
                }
              </div>
              <div className="results-table">
                {showTableView && <>
                  <ResultTable columns={columns} data={filteredData} isLoading={resultsLoading} />
                </>}
                {!showTableView && <>
                  <ResultView
                    data={filteredData}
                    RuleBadge={RuleBadge}
                    fileUrl={appraisalFileUrl}>
                  </ResultView>
                </>}
                <ReviewNotes
                  orderId={orderData.id}
                  submissionVersion={submissionVersion}
                  isEditable={isEditable}>
                </ReviewNotes>
              </div>
            </div>
          </>
        )
      }
    </>
  )
}
