import React from 'react'
import Rephrase from '../../../common/utils/Rephrase'

const TemplateHelperText = ({ validFields }) => {
  const sampleFields = validFields.slice(0, 4)
  const sampleFieldsWithExampleData = sampleFields.map((field, index) => ({
    ...field,
    example: field.example || `Example data ${index + 1}`,
  }))
  const templateExample = `Hello, regarding your information: ${sampleFieldsWithExampleData.map(({ id }) => `{{${id}}}`).join(' ')}`
  return (
    <section className="handlebar-content">
      <p>
        <Rephrase>
                    Use handlebar templates to customize this text. Handlebar templates looks like this: <strong>{'{{<supported_variable>}}'}</strong>
        </Rephrase>
      </p>
      <p>
                To see a list of fields that can be added here, type the &apos;@&apos; symbol.
      </p>
      <h3>List of supported variables:</h3>
      <ul>
        {validFields.map(field => (<li key={field.id}>{`{{${field.id}}} - ${field.display}`}</li>))}
      </ul>
      <p>
        <strong>Note when using in email: </strong>
        Some email clients may require you to use line breaks to correctly display email formatting.
        To insert line breaks into your email template, use <strong>&lt;p&gt;</strong> at the beginning
        &nbsp;of the line or paragraph and <strong>&lt;/p&gt;</strong> at the end of it.
        This will ensure there is a paragraph break between the text blocks.
      </p>
      <hr />
      <h3>Template example:</h3>
      <p>{templateExample}</p>
      <h3>How the custom message will appear:</h3>
      <p>Hello, regarding your information: {sampleFieldsWithExampleData.map(({ example }) => example).join(' ')}</p>
    </section>
  )
}

export default TemplateHelperText
